import React from 'react'
import { Link, graphql } from 'gatsby'
import styled from '@emotion/styled'
import { HeadingContainer } from '../components/common/typography'
import { WHITE_COLOR, PRIMARY_COLOR, BLACK_COLOR  } from '../components/common/color'
import bp from '../components/common/breakpoints'
import AppLayout from '../components/layouts/appLayout'
import MedicatecSVG from '../images/icons/solutions/medicatec.svg'
import OperouteSVG from '../images/icons/solutions/operoute-icon.svg'
import GitdoneSVG from '../images/icons/solutions/GitdoneIcon.svg'
import CircleSVG from '../images/icons/solutions/circle.svg'
import CedarDeploySVG from '../images/icons/solutions/cedarIcon.svg'
import SEO from '../components/seo'
import PdfSVG from '../images/icons/resources/pdf-round.svg'
import DownloadSVG from '../images/icons/resources/download-circle-fill.svg'
import PdfFile from '../pdf/Operoute_Brochure.pdf';

const resources = [
  {
    title: 'GitDone',
    external: true,
    slug: 'https://www.gitdone.io/',
    icon: <GitdoneSVG />,
  },
  {
    title: 'Operoute',
    external: true,
    slug: 'https://www.operoute.com/',
    icon: <OperouteSVG />,
  },
  {
    title: 'Medicatec',
    external: true,
    slug: 'http://www.medicatec.com/',
    icon: <MedicatecSVG />,
  },
  {
    title: 'Cedar Deployer',
    external: true,
    slug: 'https://www.cedardeployer.io/',
    icon: <CedarDeploySVG />,
  },
]

const actionsButtons = [
  {
    title: 'PDF',
    to: '/pdf-file',
    icon: <PdfSVG />,
  },
  {
    title: 'Download',
    to: '#',
    icon: <DownloadSVG />,
  }
]

const Container = styled.div`
  display: grid;
  row-gap: 2rem;
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: 0;
  padding-bottom: 6rem;
  box-sizing: border-box;

  ${bp[2]} {
    width: 90%;
  }

  ${bp[3]} {
    width: 100%;
    max-width: 1200px;
  }
`

const Card = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  column-gap: 1rem;
  background: linear-gradient(
    180deg,
    rgba(234, 234, 234, 0) 0%,
    rgba(234, 234, 234, 0.4) 95.83%
  );
  border-radius: 100px 0px 0px 100px;
  box-sizing: border-box;

  ${bp[0]} {
    grid-template-columns: 220px 1fr;
    grid-template-rows: 180px;
  }

  ${bp[1]} {
    padding: 0;
  }

  ${bp[2]} {
    column-gap: 2rem;
  }

  ${bp[3]} {
    column-gap: 4rem;
  }
`

const ImageLogoContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const IconContainer = styled.div`
  align-self: center;
  justify-self: center;
  position: relative;

  svg:first-of-type {
    background: ${WHITE_COLOR};
    border-radius: 100px;
    padding: 0.5rem;

    ${bp[0]} {
      box-shadow: 10px 10px 20px rgba(200, 180, 156, 0.14),
        -10px -10px 20px #ffffff;
    }
  }

  svg:last-child {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const SolutionLinkText = styled.span`
  align-self: center;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;

  ${bp[0]} {
    font-size: 1.5rem;
  }
`
const ActionButtonsContainer = styled.div`
  display: flex;
  align-items: center;
`
const ActionButton = styled.div`
  color: ${BLACK_COLOR};
  padding-left: 5px;
  &:hover {
    color: ${PRIMARY_COLOR};
    opacity: 1;
    cursor: pointer;
  }
`

const LinkStyle = {
  "padding-left": "5px"
}

const downloadPDF = () => {
  fetch(`${PdfFile}`).then(response => {
    response.blob().then(blob => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = 'Operoute Brochure.pdf';
        alink.click();
    })
})
};

const SolutionsPage = ({ data }) => {
  return (
    <AppLayout>
      <SEO title="Solutions" canonical="solutions" />
      <HeadingContainer title="Solutions" subtitle="" />
      <Container>
        {resources.map((resource) =>
          resource.external ? (
            <ActionButtonsContainer>
            <a
              key={resource.title.toLocaleLowerCase().replace(' ', '-')}
              href={resource.slug}
              target="_blank"
              rel="noreferrer"
            >
              <Card>
                <IconContainer>
                  <CircleSVG />
                  {!resource.icon ? (
                    <ImageLogoContainer>
                      <resource.Image fixed={data[resource.name].fixed} />
                    </ImageLogoContainer>
                  ) : (
                    resource.icon
                  )}
                </IconContainer>
                <SolutionLinkText>{resource.title}</SolutionLinkText>
              </Card>
            </a>
                {
                  (resource.title === 'Operoute') && 
                      actionsButtons.map((item, index) => (
                        (item.title === 'PDF') &&                         
                        <a
                          key={item.title + index}
                          href={item.to}
                          style= {LinkStyle}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {item.icon}
                        </a>
                        || 
                          <ActionButton
                            key={item.title}
                            to={item.to}
                            onClick={ downloadPDF }
                          >
                            {item.icon}
                          </ActionButton>
                         )
                       )
                }               
            </ActionButtonsContainer>
          ) : (
            <Link
              key={resource.title.toLocaleLowerCase().replace(' ', '-')}
              to={`${resource.slug}`}
            >
              <Card>
                <IconContainer>
                  <CircleSVG />
                  {resource.icon}
                </IconContainer>
                <SolutionLinkText>{resource.title}</SolutionLinkText>
              </Card>
            </Link>
          )
        )}
      </Container>
    </AppLayout>
  )
}

export default SolutionsPage